let data = null;

async function handleToken(token) {
  // Should only be called after submitContactForm.
  if (!data) return;

  data['g-recaptcha-response'] = token;

  try {
    const response = await fetch(config.API_URL + '/contact', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
    });

    if (!response.ok) {
      alert('The API errors have happened!');
      return false;
    }

    document.getElementById('contact-form').reset();
    console.log(response.json());
  } catch (err) {
    console.error('Form submit error: ', err);
  }

  data = null;
}

async function submitContactForm(event) {
  event.preventDefault();

  const formData = new FormData(event.currentTarget);
  data = Object.fromEntries(formData.entries());

  grecaptcha.ready(function () {
    // For invisible captcha .execute() does not return Promise<token>.
    grecaptcha.execute();
  });
}

function readyHandler() {
  const form = document.getElementById('contact-form');
  form.addEventListener('submit', submitContactForm);

  const rcEl = document.createElement('div');
  rcEl.setAttribute('id', 'recaptcha');
  rcEl.classList.add('g-recaptcha');
  rcEl.setAttribute('data-sitekey', config.RECAPTCHA_KEY);
  rcEl.setAttribute('data-size', 'invisible');
  rcEl.setAttribute('data-callback', 'handleToken');
  form.appendChild(rcEl);

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = config.RECAPTCHA_URL;
  document.body.append(s);
}

if (document.readyState !== 'loading') {
  readyHandler();
} else {
  document.addEventListener('DOMContentLoaded', readyHandler);
}
